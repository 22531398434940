<template>
    <ListaAgenda />
</template>

<script>
import ListaAgenda from '@/components/Gadget/ListaAgenda'
export default {
  components: {ListaAgenda},
  data(){
    return{}
  }
}
</script>

<style>

</style>