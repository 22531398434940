<template>
  <v-container>
    <div class="header" style="display:flex;flex-direction:column;">
       <v-container class="">
          <h3>Agenda</h3>

          <v-row style="margin-top:10px;" v-if="!exibirCriacaoAgenda" >
                <v-col>
                    <v-text-field label="Dia" v-model="diaselecionado" type="date" style="width:100%;margin-right:5%;" outlined return-object hide-details dense >
                    </v-text-field>
                </v-col>
                <v-col v-if="usuariomaster">
                    <v-select color v-model="usuarioimplantador" label="Usuario Implantador" item-text="nome" item-value="id" :items="usuarios" outlined return-object hide-details dense>
                    </v-select>
                </v-col>
                <v-col>
                    <v-btn  elevation="" color="info" style="width:30%;" @click="requisitarAgendaMarcada()" block>Buscar</v-btn>
                </v-col>
                <v-col>
                    <v-btn  elevation="" color="success" style="width:30%;" @click="setExibirCriacaoAgenda()" block>Criar Evento</v-btn>
                </v-col>
          </v-row>

            <v-row style="margin-top:10px;"  v-if="exibirCriacaoAgenda && usuariomaster" >

                <v-col cols="12" md="2">
                    <v-select @change="sethorarios()" color v-model="usuarioimplantador" label="Usuario Implantador" item-text="nome" item-value="id" :items="usuarios" outlined return-object hide-details dense>
                    </v-select>
                </v-col>

                <v-col cols="12" md="2">
                    <v-text-field label="Dia" v-model="diaselecionado" type="date" style="width:100%;margin-right:5%;" outlined return-object hide-details dense 
                      @change="sethorarios()">
                    </v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select  :items="horarios" label="Horário" name="horario" v-model="horario"
                      outlined dense hide-details ></v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field label="Descreva o Evento" v-model="obs" type="text" style="width:100%;margin-right:5%;" outlined return-object hide-details dense >
                    </v-text-field>
                    
                </v-col>

            </v-row>

            <v-row style="margin-top:10px;"  v-if="exibirCriacaoAgenda && !usuariomaster" >

                <v-col cols="12" md="3">
                    <v-text-field label="Dia" v-model="diaselecionado" type="date" style="width:100%;margin-right:5%;" outlined return-object hide-details dense 
                      @change="sethorarios()">
                    </v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select  :items="horarios" label="Horário" name="horario" v-model="horario"
                      outlined dense hide-details ></v-select>
                </v-col>

                <v-col cols="12" md="7">
                  <v-text-field label="Descreva o Evento" v-model="obs" type="text" style="width:100%;margin-right:5%;" outlined return-object hide-details dense >
                    </v-text-field>
                    
                </v-col>

            </v-row>

            

            <v-row style="margin-top:10px;" v-if="exibirCriacaoAgenda" >

                <v-col cols="12" md="4">
                    <v-select color v-model="sistema" label="Sistema" item-text="nome" item-value="id" :items="sistemas" outlined return-object hide-details dense>
                    </v-select>
                </v-col>
              
                <v-col cols="12" md="4">
                    <v-select color v-model="cliente" label="Cliente" item-text="nome" item-value="id" :items="clientes" outlined return-object hide-details dense>
                    </v-select>
                </v-col>

                
            </v-row>


            <v-row style="margin-top:10px;"  v-if="exibirCriacaoAgenda">
           
                <v-col cols="12" md="6">
                    <v-btn  elevation="" color="error" style="width:30%;" @click="setExibirCriacaoAgenda()" block>Cancelar</v-btn>
                </v-col>
                <v-col cols="12" md="6">
                    <v-btn  elevation="" color="success" style="width:30%;" @click="criarAgenda()" block>Salvar</v-btn>
                </v-col>
                
            </v-row>

        </v-container>

        <!-- <div>
          <span>
            
     
          </span>
        </div>
        <div style="display:flex;align-items:center;margin-top:20px;">

        </div>
       -->
      <div class="grow"/>
        <!-- <button type="button" class="btn" @click="$router.push('/criar/gadget')">novo gadget</button> -->
      </div>
    <section class="wrapper-list">
      <!-- lista de usuarios -->
      <v-data-table 
        :items="items"
        :headers="headers"
        :items-per-page="6"
        >

            <template v-slot:[`item.horaagenda`]="{ item }">
              {{item.horaagenda}} H
            </template>

      </v-data-table>
    </section>
  </v-container>
</template>

<script>
import ClienteServico from '@/services/cliente.service.js'
import ImplantacaoServico from '@/services/implantacao.service.js'
import SistemaServico from '@/services/sistema.service.js'
import UsuarioServico from '@/services/usuario.service.js'

import dayjs from 'dayjs'
export default {
  data(){
    return{
        diaselecionado:null,
        exibirCriacaoAgenda:false,
        obs:null,
        usuariomaster:false,
        idusuarioimplantador:null,
        usuarioimplantador:null,
        clientes:[],
        cliente:{id:0,nome:'Sem Cliente Selecionado'},
        implantadores:[],
        implantador:{},
        sistemas:[],
        sistema:{id:0,nome:'Sem Sistema Selecionado'},
        horarios: [],
        horario: null,
      headers: [
        { text: 'Data',  value: 'dataagenda', width:'10%' },
        { text: 'Hora',  value: 'horaagenda', width:'10%' },
        { text: 'Evento', value: 'observacao', width:'30%' },
        { text: 'Sistema', value: 'nomesistema' , width:'20%'},
        { text: 'Cliente', value: 'nomecliente', width:'30%' },
      ],
      items: [],
    }
  },
  methods:{
    async criarAgenda(){

      let idusuario = null
      if(this.usuariomaster){
        idusuario = this.usuarioimplantador.id
      }else{
        idusuario = localStorage.getItem('idusuario')
      }

      await ImplantacaoServico.agendarEvento({
                          idusuarioimplantador:idusuario, 
                          dataagenda:this.diaselecionado,
                          idcliente:this.cliente.id,
                          horaagenda:this.horario,
                          idsistema:this.sistema.id,
                          email:this.usuarioimplantador.email,
                          observacao:this.obs}).then(res=> res.data)
      
      this.cliente = {id:0,nome:'Sem Cliente Selecionado'}
      this.sistema = {id:0,nome:'Sem Sistema Selecionado'}
      this.obs = null
      this.horario = null
      this.diaselecionado = dayjs().format('YYYY-MM-DD')
      

      this.setExibirCriacaoAgenda()

    },
    async setExibirCriacaoAgenda(){
      this.exibirCriacaoAgenda = !this.exibirCriacaoAgenda
      await this.requisitarAgendaMarcada()
      this.requisitarHorarios(this.diaselecionado)
    },
    async requisitarAgendaMarcada(){
      let idusuario = null
      if(this.usuariomaster){
        idusuario = this.usuarioimplantador.id
      }else{
        idusuario = localStorage.getItem('idusuario')
      }

      this.items = await ImplantacaoServico.buscarAgendaMarcada({idusuarioimplantador:idusuario,dataagenda:this.diaselecionado}).then(res=> res.data)
      console.log(this.items)
    },
    async requisitarClientes(){
      let listaclientes = await ClienteServico.buscar().then(res => res.data)
      this.clientes = [{id:0,nome:'Sem Cliente Selecionado'}]
      this.clientes = this.clientes.concat(listaclientes)

    },
    async requisitarSistemas(){
      let listadesistema = await SistemaServico.buscar({semimagem:1}).then(res => res.data)
      this.sistemas = [{id:0,nome:'Sem sistema Selecionado'}]
      this.sistemas = this.sistemas.concat(listadesistema)
    },
    async requisitarUsuarios(){
      this.usuarios = await UsuarioServico.buscar({tipo:1}).then(res => res.data)
      this.usuar
    },
    sethorarios(){
      this.requisitarHorarios(this.diaselecionado)
    },
    async requisitarHorarios(diaagenda){
    
      this.horarios = []
      let listahorariobase = [9,10,11,14,15,16,17]
      let listahorario = [9,10,11,14,15,16,17]

      let idusuario = null
      if(this.usuariomaster){
        idusuario = this.usuarioimplantador.id
      }else{
        idusuario = localStorage.getItem('idusuario')
      }

      const implantacao = await ImplantacaoServico.buscarAgendaMarcada({idusuarioimplantador:idusuario,dataagenda:diaagenda}).then(res => res.data)
      
      implantacao.forEach(hora =>{
         
          listahorario.forEach(hora2 =>{
              if(hora.horaagenda == hora2){
                 listahorariobase = listahorariobase.filter(x =>{
                   return x != hora2
                 })
              }
          })
      })

        for(let i=0,leni = listahorariobase.length; i < leni; i++){
          if(listahorariobase[i]<10){
            listahorariobase[i] = "0"+listahorariobase[i]+":00"
          }else{
            listahorariobase[i] = listahorariobase[i]+":00"
          }
          
        }
        this.horarios = listahorariobase
           
    }
  },

  async mounted(){
      this.diaselecionado = dayjs().format('YYYY-MM-DD')
      this.idusuarioimplantador = localStorage.getItem('idusuario')
      
      await this.requisitarClientes()
      await this.requisitarSistemas()
      await this.requisitarHorarios(this.diaselecionado)
      await this.requisitarAgendaMarcada()
      await this.requisitarUsuarios()

      this.usuarioimplantador = null
      this.usuarioimplantador = this.usuarios.find(x =>{
         return x.id == this.idusuarioimplantador
      })

      if(this.idusuarioimplantador == 1 || this.idusuarioimplantador == 19 || this.idusuarioimplantador == 75 || this.idusuarioimplantador == 152){
          this.usuariomaster = true
      }


  }
}
</script>

<style lang="scss" scoped>

.header{
  display: flex;
  padding: 1em;
      margin: 0 auto;
    width: 80vw;
    max-width: 100%;

  >h3{
    letter-spacing: 0.02em;
  }
  margin: 1em auto;
  background: white;
  height: fit-content;
  border-radius: 10px;
  >span{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.section-header{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1em 0;
}

.section-nav{
  display: flex;
  align-items: center;
  justify-content: center;

  ul{
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #eff3e7;
    padding: 1em;
    width: 100%;

    li{
      margin: 0 .5em;
      padding: 1em 1.5em;
      border-radius: 50px;
      background: #eff3e7;
  box-shadow:  4px 4px 10px #d9d9d9,
             4px 4px 10px #ffffff;
    }
  }
}

.wrapper-list{
  margin: 0 auto;
  width: 80vw;
  max-width: 100%;
  @media screen and (max-width: 768px) {
    width: 90vw;    
  }
}

.btn{
      padding: .75em 1em;
      line-height: 1.5;
      color: white;
      font-weight: bold;
      letter-spacing: 0.02em;
      background: #1377f2;
      border-radius: 10px;
      text-transform: capitalize;
  }

.grow{
  flex: 1;
}

</style>